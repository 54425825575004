header{
    height: 100vh;
    padding-top: 5rem;
    overflow: hidden;
}

.header_container {
    text-align: center;
    height: 100%;
    position: relative;
}


/*Buttons*/
.buttons{
    margin-top: 2.5rem;
    display: flex;
    gap: 1.2rem;
    justify-content: center;
}
.buttons .btn-primary:hover {
    color: white;
}

.header_socials{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap:0.8rem;
    position: absolute;
    left: 0;
    bottom: 3rem;
}
.header_socials>a>*{
    size: 5rem;
}

.header_socials::after{
    content: "";
    width: 1px;
    height: 2rem;
    background: var(--color-accent-green1);
}

.me-image{
    display: flex;
    width: 25rem;
    height: 25rem;
    position: relative;
    left: calc(50% - 12.5rem);
    border-radius: 1rem;
    overflow: hidden;
}

.me-image img{
    margin-right: auto;
    margin-left: auto;
    width: 25rem;
    z-index: 999;
    overflow: hidden;
}

.scroll_down{
    position: absolute;
    right: -2.5rem;
    bottom: 5rem;
    transform: rotate(90deg);
    font-weight: 300;
    font-size: 0.9rem;
}
.header_container a:hover {
    color: var(--color-accent-green1);
}
/* Media queries(medium devices) */
@media screen and (max-width:1024px){
    header{
        height: 50vh;
    }
}


/* Media queries(medium small) */
@media screen and (max-width:600px){
    header{
        height: 90vh;
    }

    .header_socials,.scroll_down{
        display: none;
    }
}
canvas {
    position: absolute;
    background-color: rgba(255, 255, 255, 0);
    z-index: 998;
    width: 25rem;
    height: 25rem;
    opacity: 95%;


}

.me{
    width: 25rem;
    background: linear-gradient(
            45deg, transparent, var(--color-accent-green1), transparent
    );
    display: grid;
    place-items: center;
    height: 25rem;
    margin-top: 4rem;
    margin-left: auto;
    margin-right: auto;
    border-radius: 1rem;
}

.me-image{
    overflow: hidden;
    transform: rotate(10deg);
    transition: var(--transition1);
}

.me-image:hover{
    transform: rotate(0deg);
}