.experience_container{
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 2rem;
}

.experience_container > div{
    background: var(--color-accent-green1-op);
    padding: 2.4rem 5rem;
    border-radius: 2rem;
    border: 1px solid transparent;
    transition: var(--transition1);
    color: white;
}

.experience_container > div:hover{
    background: var(--color-accent-green2-op);
    cursor: default;
}

.experience_container > div h3{
    text-align: center;
    margin-bottom: 2.5rem;
}

.experience_content{
    display: grid;
    grid-template-columns: 1fr 1fr;
    row-gap: 2rem;
    
}

.experience_details{
    display: flex;
    gap: 1rem;
    text-align: left;
}

.experience_details small, .experience_details h4{
    text-align: left;
}

.experience_details-icon{
    margin-top:auto;
    margin-bottom:auto;
}

/* Media queries(medium devices) */
@media screen and (max-width:1024px){
    .experience_container{
        grid-template-columns: 1fr;
    }

    .experience_container > div{
        width: 80%;
        padding: 2rem;
        margin: 0 auto;
    }

    .experience_content{
        padding: 1rem;
    }
}


/* Media queries(medium small) */
@media screen and (max-width:600px){
    .experience_container{
        gap: 1rem;
    }

    .experience_container > div{
        width: 100%;
        padding: 2rem 1rem;
    }
}