.parent {
    width: 5rem;
    height: 5rem;
    display: flex;
    justify-content: center;
    align-items: center;
}

.icon-container {
    position: sticky;
    width: 1rem;
    height: 1rem;
}

.icon {
    width: 1rem;
    height: 1rem;
    background-color: red;
    border-radius: 50%;
    transition: transform 0.1s ease-out;
}
