#blogPage{
    margin-top: 1rem;
}

.titleHeader{
    color: white;
    background-color: var(--color-accent-green1-op);
    border-radius: 1rem;
    font-size: 2rem;
    margin-left: -50rem;
    padding-left: 45rem;
    display: flex;
}

.titleHeader h3{
    text-align: left;
    padding-left: 0.5rem;
}
.blogPost{
    font-weight: normal;
    color: white;
}
.blogPost p{
    font-weight: lighter;
    padding-bottom: 0.5rem;
}

.blogPost h3{
    text-align: left;
}
.blogPost .titleAndDate{
    display: flex;
    justify-content: space-between;
    color: white;
    padding-top: 0.5rem;
}

.blogPost .titleAndDate h4,.blogPost .titleAndDate h5{
    font-size: 2rem;
}

.alinea{
    display: flex;
    padding-bottom: 2rem;
}
.alinea .blogText{
    width: 55%;
    overflow: hidden;
}

.alinea .blogPicture{
    width: 45%;
}

.titleHeader .backIcon{
    padding-top: 0.25rem;
    cursor: pointer;
}
.titleHeader .backIcon .backArrow1,.titleHeader .backIcon .backArrow2{
    opacity: 0;
}
.titleHeader .backIcon .backArrow1{
    transition: all 300ms ease-in-out;
}
.titleHeader .backIcon .backArrow2{
    transition: all 300ms ease-in-out;
}

.titleHeader .backIcon:hover .backArrow1,.titleHeader .backIcon:hover .backArrow2{
    opacity: 1;
}

.titleHeader .backIcon:hover .backArrow2{
    transition-delay: 0.2s;
}